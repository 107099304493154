// Serbia
export const locale = {
  lang: 'sr',
  data: {
    TRANSLATOR: {
      SELECT: 'Izaberite jezik',
    },
    MENU: {
      REGISTER: 'Registruj se',
      NEW: 'Novi',
      ACTIONS: 'Akcije',
      CREATE_POST: 'Kreiraj novi post',
      PAGES: 'Stranice',
      FEATURES: 'Karakteristike',
      APPS: 'Aplikacije',
      DASHBOARD: 'Kontrolna tabla',
    },
    AUTH: {
      GENERAL: {
        OR: 'Ili',
        SUBMIT_BUTTON: 'Pošalji',
        NO_ACCOUNT: 'Nemate nalog?',
        SIGNUP_BUTTON: 'Registruj se',
        FORGOT_BUTTON: 'Zaboravili ste lozinku',
        BACK_BUTTON: 'Nazad',
        PRIVACY: 'Privatnost',
        LEGAL: 'Pravne napomene',
        CONTACT: 'Kontakt',
        TITLE: 'Brzo, efikasno i produktivno',
        DESCRIPTION: 'Pojednostavite operacije u restoranu za objedovanje, poneti i dostavu. Od kreiranja narudžbi do generisanja računa, olakšajte rad vašem timu i poboljšajte zadovoljstvo kupaca uz naše sveobuhvatno rešenje.'
      },
      LOGIN: {
        TITLE: 'Prijavi se',
        BUTTON: 'Prijavi se',
      },
      FORGOT: {
        TITLE: 'Zaboravljena lozinka?',
        DESC: 'Unesite vaš email za resetovanje lozinke',
        SUCCESS: 'Vaš nalog je uspešno resetovan.'
      },
      REGISTER: {
        MAX_NUM_ADDONS: 'Možete izabrati najviše {{maxAddons}} dodataka',
        ANY_NUM_ADDONS: 'Možete izabrati neograničen broj dodataka'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Puno ime',
        PASSWORD: 'Lozinka',
        CONFIRM_PASSWORD: 'Potvrdite lozinku',
        USERNAME: 'Korisničko ime'
      },
      CART: {
        VAT_PERCENT_LABEL: 'PDV uključen ({{vatPercent}}%)'
      },
      VALIDATION: {
        INVALID: '{{name}} nije validan',
        REQUIRED: '{{name}} je obavezan',
        MIN_LENGTH: 'Minimalna dužina {{name}} je {{min}} karaktera',
        AGREEMENT_REQUIRED: 'Prihvatanje uslova i odredbi je obavezno',
        NOT_FOUND: '{{name}} nije pronađen',
        INVALID_LOGIN: 'Podaci za prijavu su netačni',
        REQUIRED_FIELD: 'Obavezno polje',
        MIN_LENGTH_FIELD: 'Minimalna dužina polja:',
        MAX_LENGTH_FIELD: 'Maksimalna dužina polja:',
        INVALID_FIELD: 'Nevalidno polje',
      }
    },
    CHECKOUT: {
      SPLIT_PAYMENT: {
        DUE_LABEL: "Iznos koji treba da se plati za podeljeno plaćanje je <strong>{{dueAmount}}</strong>.",
        AMOUNT_LABEL: "Svaka osoba će platiti jednaku sumu od <strong>{{amount}}</strong>.",
        MESSAGE: "Iznos koji treba da se plati za podeljeno plaćanje od <strong>{{dueAmount}}</strong> između <strong>{{num}}</strong> osoba. Svaka osoba će platiti jednaku sumu od <strong>{{amount}}</strong>."
      }
    },
    SETTINGS: {
      MENU_DATA: {
        TITLE: "Ažuriraj podatke menija",
        DESCRIPTION: "Povremeno ažuriramo stavke restorana, kako bi POS sistem bio usklađen sa vašim menijem i funkcionisao autonomno. Poslednje ažuriranje je bilo",
      },
      SYNCHRONIZE: {
        TITLE: "Sinhronizuj lokalne podatke",
        DESCRIPTION: "Sinhronizujemo lokalne podatke sa serverom svake nekoliko minuta. Možete pokrenuti sinhronizaciju ručno klikom na ovaj dugme. Poslednja sinhronizacija je bila",
      }
    },
    "Unlock POS": "Otključaj POS",
    "Terms": "Uslovi",
    "Plans": "Planovi",
    "Contact Us": "Kontaktirajte nas",
    "1/2 of bill": "1/2 računa",
    "1/3 of bill": "1/3 računa",
    "1/4 of bill": "1/4 računa",
    "ASAP": "Što pre moguće",
    "Action": "Akcija",
    "Add a Bixolon Web printer": "Dodaj Bixolon Web štampač",
    "Add a printer:": "Dodaj štampač:",
    "Test print": "Test štampanje",
    "Printer": "Štampač",
    "Line Width": "Širina linije",
    "Selected printer already saved": "Štampač je već sačuvan",
    "Printer is not connected": "Štampač nije povezan",
    "Adjustment: ": "Podešavanje:",
    "Any": "Bilo koji",
    "Bixolon Web Print": "Web štampanje Bixolon",
    "CART": {
      "VAT_PERCENT_LABEL": "PDV uključen ({{vatPercent}}%)"
    },
    "Cancel": "Otkaži",
    "Continue": "Nastavi",
    "Coupon: ": "Kupon:",
    "Creation Time": "Vreme kreiranja",
    "Customer": "Kupac",
    "Decrease": "Smanji",
    "Delivery Time": "Vreme dostave",
    "Device": "Uređaj",
    "Dinego, your sales partner": "Dinego, vaš prodajni partner",
    "Choose user": "Izaberite korisnika",
    "Select user to unlock Dinego POS, and start your shift": "Izaberite korisnika da otključate Dinego POS i započnete svoju smenu",
    "Due": "Duguje se",
    "Email": "Email",
    "Password": "Lozinka",
    "Enter your email to reset your password": "Unesite svoj email da biste resetovali lozinku",
    "Forgot Password?": "Zaboravili ste lozinku?",
    "Email is required": "Email je obavezan",
    "Email is invalid": "Email nije validan",
    "Email should have at least 3 symbols": "Email treba da sadrži najmanje 3 karaktera",
    "Email should have maximum 360 symbols": "Email treba da sadrži najviše 360 karaktera",
    "Password is required": "Lozinka je obavezna",
    "Password is invalid": "Lozinka nije validna",
    "Password should have at least 3 symbols": "Lozinka treba da sadrži najmanje 3 karaktera",
    "Password should have maximum 100 symbols": "Lozinka treba da sadrži najviše 100 karaktera",
    "Full Name": "Puno ime",
    "Full Name is required": "Puno ime je obavezno",
    "Full Name should have at least 3 symbols": "Puno ime mora imati najmanje 3 znaka",
    "Full Name should have maximum 100 symbols": "Puno ime može imati najviše 100 znakova",
    "Please enter both name and surname": "Molimo unesite i ime i prezime",
    "Phone": "Telefon",
    "Phone number is required": "Broj telefona je obavezan",
    "Phone number should have at least 3 symbols": "Broj telefona mora imati najmanje 3 znaka",
    "Phone number should have maximum 100 symbols": "Broj telefona može imati najviše 100 znakova",
    "Please enter customer information": "Molimo unesite informacije o kupcu",
    "Please enter delivery information": "Molimo unesite informacije o dostavi",
    "Delivery address": "Adresa za dostavu",
    "Street address is required": "Adresa ulice je obavezna",
    "Street address must be at least 3 characters": "Adresa ulice mora imati najmanje 3 karaktera",
    "Street address must be at most 255 characters": "Adresa ulice može imati najviše 255 karaktera",
    "Type in your address, or position pin on a map for improved accuracy.": "Unesite svoju adresu ili postavite pin na mapi radi boljeg preciznosti.",
    "General": "Opšte",
    "IP Address": "IP adresa",
    "IP Address is required": "IP adresa je obavezna",
    "Including Tip": "Uključujući napojnicu",
    "Increase": "Povećaj",
    "Language": "Jezik",
    "Add a printer using one of above methods": "Dodajte štampač koristeći jednu od gore navedenih metoda",
    "List of saved printers": "Lista sačuvanih štampača",
    "Load to cart": "Dodaj u korpu",
    "Location": "Lokacija",
    "Lock": "Zaključaj",
    "Log Out": "Odjavi se",
    "Logical name": "Logičko ime",
    "Logical name is required": "Logičko ime je obavezno",
    "Logout": "Odjava",
    "Mine only": "Samo moji",
    "Miscellaneous": "Razno",
    "N/A": "N/A",
    "No connected printers": "Nema povezanih štampača",
    "OK": "OK",
    "Order #": "Narudžba br.",
    "Order Status": "Status narudžbe",
    "Order Type": "Vrsta narudžbe",
    "Orders": "Narudžbe",
    "Outstanding": "Izuzetno",
    "POS Language": "Jezik POS-a",
    "POS Theme": "Tema POS-a",
    "Payment #": "Plaćanje br.",
    "Payment Method": "Metod plaćanja",
    "Payment Status": "Status plaćanja",
    "Payment Time": "Vreme plaćanja",
    "Payment adjustment": "Podešavanje plaćanja",
    "Payment amount": "Iznos plaćanja",
    "Payments": "Plaćanja",
    "Pickup Time": "Vreme preuzimanja",
    "Please enter a valid number": "Molimo unesite validan broj",
    "Please wait": "Molimo sačekajte",
    "Port number": "Broj porta",
    "Port number is required": "Broj porta je obavezan",
    "Preview order": "Pregled narudžbe",
    "Print order": "Štampaj narudžbu",
    "Print receipt": "Štampaj račun",
    "Printer configuration": "Konfiguracija štampača",
    "Receipts": "Računi",
    "Reference #:": "Referenca br.:",
    "Results per page": "Rezultati po stranici",
    "Save": "Sačuvaj",
    "Search coupons": "Pretraži kupone",
    "Coupon": "Kupon",
    "Search tables": "Pretraži stolove",
    "Select coupon to apply": "Izaberite kupon za primenu",
    "Select table": "Izaberite sto",
    "Sent password reset, please check your email": "Resetovanje lozinke poslato, molimo proverite svoj email",
    "Settings": "Podešavanja",
    "Data synchronization": "Sinhronizacija podataka",
    "Update menu data": "Ažuriraj podatke menija",
    "Synchronise local data": "Sinhronizuj lokalne podatke",
    "Sign in": "Prijavite se",
    "Sorry, looks like there are some errors detected, please try again": "Izvinjavamo se, izgleda da su otkrivene neke greške, molimo pokušajte ponovo",
    "Split payment equally": "Podeli plaćanje ravnomerno",
    "Submit": "Pošalji",
    "Sync now": "Sinhronizuj sada",
    "Syncing": "Sinhronizacija",
    "Table": "Sto",
    "Tables": "Stolovi",
    "The login details are incorrect": "Podaci za prijavu su netačni",
    "Theme": "Tema",
    "There are no active coupons for your restaurant": "Nema aktivnih kupona za vaš restoran",
    "There are no active tables for your restaurant": "Nema aktivnih stolova za vaš restoran",
    "There are no coupons matching your search": "Nema kupona koji odgovaraju vašoj pretrazi",
    "There are no orders matching your search": "Nema narudžbi koje odgovaraju vašoj pretrazi",
    "There are no receipts matching your search": "Nema računa koji odgovaraju vašoj pretrazi",
    "There are no tables matching your search": "Nema stolova koji odgovaraju vašoj pretrazi",
    "Tip: ": "Savjet: ",
    "Today": "Danas",
    "Update application language by choosing your preferred language": "Ažurirajte jezik aplikacije izborom vašeg preferiranog jezika",
    "Update application skin color to dark or light, or use system preferred": "Ažurirajte boju kože aplikacije na tamnu ili svetlu, ili koristite sistemom preferiranu",
    "Update now": "Ažuriraj sada",
    "You don't have any floorplans created yet": {
      " Please go to your admin panel and create some": {
        "": "Još niste kreirali nijedan plan prostora. Molimo idite na vaš administratorski panel i kreirajte neki"
      }
    },
    "discount": "popust",
    "discount on entire order": "popust na celu narudžbu",
    "out of": "od",
    "Direct sale": "Direktna prodaja",
    "No table": "Nema stola",
    "Search for products or categories": "Pretraži proizvode ili kategorije",
    "There are no categories or products matching your search": "Nema kategorija ili proizvoda koji odgovaraju vašoj pretrazi",
    "Product addons": "Dodaci za proizvode",
    "Add course": "Dodaj jelo",
    "Total": "Ukupno",
    "Clear order": "Obriši narudžbu",
    "Item amount": "Iznos stavke",
    "Add Adjustment": "Dodaj podešavanje",
    "Update Adjustment": "Ažuriraj podešavanje",
    "Bill adjustment": "Podešavanje računa",
    "Delivery cost": "Trošak dostave",
    "Service fee": "Naknada za usluge",
    "Tip amount": "Iznos napojnice",
    "Paid amount": "Iznos plaćen",
    "Total Due": "Ukupno duga",
    "Total paid": "Ukupno plaćeno",
    "Remove Course": "Ukloni jelo",
    "Apply Coupon": "Primeni kupon",
    "Remove Coupon": "Ukloni kupon",
    "Fire Order": "Pošalji narudžbu",
    "Fire Course": "Pošalji jelo",
    "Remove Item": "Ukloni stavku",
    "Order Amount": "Iznos narudžbe",
    "Coupon Amount": "Iznos kupona",
    "Adjustment Amount": "Iznos podešavanja",
    "Tip Amount": "Iznos napojnice",
    "VAT Amount": "Iznos PDV-a",
    "VAT Number": "PDV broj",
    "Payment Receipt": "Račun o plaćanju",
    "Total Paid": "Ukupno plaćeno",
    "Thank you": "Hvala",
    "Not paid": "Nije plaćeno",
    "Current orders": "Trenutne narudžbe",
    "Order archive": "Arhiva narudžbi",
    "Order": "Narudžba",
    "Update Order": "Ažuriraj narudžbu",
    "Print Receipt": "Štampaj račun",
    "Transfer order": "Prenesi narudžbu",
    "Clear table": "Obriši sto",
    "Print receipts": "Štampaj račune",
    "Transfer orders": "Prenesi narudžbe",
    "New order": "Nova narudžba",
    "There are no archive orders for this table": "Nema arhiviranih narudžbi za ovaj sto",
    "There are no active orders for this table": "Nema aktivnih narudžbi za ovaj sto",
    "Pay": "Plati",
    "Tip": "Napojnica",
    "Payment method": "Metod plaćanja",
    "Simply click on the button displaying the current value to set a custom amount": "Jednostavno kliknite na dugme koje prikazuje trenutnu vrednost da biste postavili prilagođeni iznos",
    "Customize the tip, or set it manually to your preference": "Prilagodite napojnicu ili je postavite ručno prema vašoj želji",
    "Adjust received amount by setting a custom value with a simple click": "Prilagodite primljeni iznos postavljanjem prilagođene vrednosti jednostavnim klikom",
    "This is change that need to be returned to a customer, based on how much he is paying and how.": "Ovo je kusur koji treba da se vrati kupcu, na osnovu koliko plaća i kako.",
    "Keep change as tip": "Zadržite kusur kao napojnicu",
    "Received amount": "Primljeni iznos",
    "Change": "Kusur",
    "Split": "Podeli",
    "Bill": "Račun",
    "Checkout": "Plaćanje",
    "Paid At": "Plaćeno u",
    "Paid With": "Plaćeno sa",
    "Ordered At": "Naručeno u",
    "Dine-In": "Obrok u restoranu",
    "Takeaway": "Za poneti",
    "Delivery": "Dostava",
    "This week": "Ove nedelje",
    "Last week": "Prošle nedelje",
    "This month": "Ovog meseca",
    "Last month": "Prošlog meseca",
    "Custom range": "Prilagođeni opseg",
    "Administrator": "Administrator",
    "Restaurant manager": "Menadžer restorana",
    "Employee": "Zaposleni",
    "Unknown": "Nepoznat",
    "Created": "Kreirano",
    "Pending": "Na čekanju",
    "Accepted": "Prihvaćeno",
    "Ready": "Spremno",
    "Finished": "Završeno",
    "Authorised": "Odobreno",
    "Captured": "Zabeleženo",
    "Settled": "Zatvoreno",
    "Canceled": "Otkaženo",
    "Refunded": "Povraćeno",
    "Failed": "Neuspešno",
    "Cash": "Gotovina",
    "Credit Card": "Kreditna kartica",
    "Apple pay": "Apple Pay",
    "Google pay": "Google Pay",
    "Twint": "Twint",
    "Clear": "Obriši",
    "Apply": "Primeni",
    "Course": "Jelo"
  }
};
