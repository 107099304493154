// Germany
export const locale = {
  lang: 'de',
  data: {
    TRANSLATOR: {
      SELECT: 'Sprache auswählen',
    },
    MENU: {
      REGISTER: 'Registrieren',
      NEW: 'Neu',
      ACTIONS: 'Aktionen',
      CREATE_POST: 'Neuen Beitrag erstellen',
      PAGES: 'Seiten',
      FEATURES: 'Funktionen',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Oder',
        SUBMIT_BUTTON: 'Absenden',
        NO_ACCOUNT: 'Kein Konto?',
        SIGNUP_BUTTON: 'Registrieren',
        FORGOT_BUTTON: 'Passwort vergessen',
        BACK_BUTTON: 'Zurück',
        PRIVACY: 'Datenschutz',
        LEGAL: 'Rechtliche Hinweise',
        CONTACT: 'Kontakt',
        TITLE: 'Schnell, effizient und produktiv',
        DESCRIPTION: 'Vereinfachen Sie die Abläufe in Ihrem Restaurant für Bestellungen, Takeaways und Lieferungen. Von der Erstellung von Bestellungen bis zur Rechnungsstellung – erleichtern Sie Ihrem Team die Arbeit und verbessern Sie die Kundenzufriedenheit mit unserer umfassenden Lösung.'
      },
      LOGIN: {
        TITLE: 'Anmelden',
        BUTTON: 'Anmelden',
      },
      FORGOT: {
        TITLE: 'Passwort vergessen?',
        DESC: 'Geben Sie Ihre E-Mail-Adresse zum Zurücksetzen Ihres Passworts ein',
        SUCCESS: 'Ihr Konto wurde erfolgreich zurückgesetzt.'
      },
      REGISTER: {
        MAX_NUM_ADDONS: 'Sie können maximal {{maxAddons}} Ergänzungen auswählen',
        ANY_NUM_ADDONS: 'Sie können beliebig viele Ergänzungen auswählen'
      },
      INPUT: {
        EMAIL: 'E-Mail',
        FULLNAME: 'Vollständiger Name',
        PASSWORD: 'Passwort',
        CONFIRM_PASSWORD: 'Passwort bestätigen',
        USERNAME: 'Benutzername'
      },
      CART: {
        VAT_PERCENT_LABEL: 'MwSt. enthalten ({{vatPercent}}%)'
      },
      VALIDATION: {
        INVALID: '{{name}} ist nicht gültig',
        REQUIRED: '{{name}} ist erforderlich',
        MIN_LENGTH: 'Minimale Länge von {{name}} ist {{min}} Zeichen',
        AGREEMENT_REQUIRED: 'Die Akzeptanz der Geschäftsbedingungen ist erforderlich',
        NOT_FOUND: '{{name}} wurde nicht gefunden',
        INVALID_LOGIN: 'Anmeldedaten sind nicht korrekt',
        REQUIRED_FIELD: 'Erforderliches Feld',
        MIN_LENGTH_FIELD: 'Minimale Länge des Feldes:',
        MAX_LENGTH_FIELD: 'Maximale Länge des Feldes:',
        INVALID_FIELD: 'Ungültiges Feld',
      }
    },
    CHECKOUT: {
      SPLIT_PAYMENT: {
        DUE_LABEL: "Der zu zahlende Betrag für die geteilte Zahlung beträgt <strong>{{dueAmount}}</strong>.",
        AMOUNT_LABEL: "Jede Person zahlt einen gleichen Betrag von <strong>{{amount}}</strong>.",
        MESSAGE: "Der zu zahlende Betrag für die geteilte Zahlung von <strong>{{dueAmount}}</strong> unter <strong>{{num}}</strong> Personen. Jede Person zahlt einen gleichen Betrag von <strong>{{amount}}</strong>."
      }
    },
    SETTINGS: {
      MENU_DATA: {
        TITLE: "Menüdaten aktualisieren",
        DESCRIPTION: "Wir aktualisieren regelmäßig die Artikel Ihres Restaurants, damit das POS-System mit Ihrem Menü synchronisiert wird und autonom funktioniert. Das letzte Update war",
      },
      SYNCHRONIZE: {
        TITLE: "Lokale Daten synchronisieren",
        DESCRIPTION: "Wir synchronisieren die lokalen Daten alle paar Minuten mit dem Server. Sie können die Synchronisation manuell starten, indem Sie auf diese Schaltfläche klicken. Die letzte Synchronisation war",
      }
    },
    "Unlock POS": "POS entsperren",
    "Terms": "Bedingungen",
    "Plans": "Pläne",
    "Contact Us": "Kontaktieren Sie uns",
    "1/2 of bill": "1/2 Rechnung",
    "1/3 of bill": "1/3 Rechnung",
    "1/4 of bill": "1/4 Rechnung",
    "ASAP": "So schnell wie möglich",
    "Action": "Aktion",
    "Add a Bixolon Web printer": "Bixolon Webdrucker hinzufügen",
    "Add a printer:": "Drucker hinzufügen:",
    "Test print": "Testdruck",
    "Printer": "Drucker",
    "Line Width": "Linienbreite",
    "Selected printer already saved": "Drucker bereits gespeichert",
    "Printer is not connected": "Drucker ist nicht verbunden",
    "Adjustment: ": "Anpassung:",
    "Any": "Jeder",
    "Bixolon Web Print": "Bixolon Webdruck",
    "CART": {
      "VAT_PERCENT_LABEL": "MwSt. enthalten ({{vatPercent}}%)"
    },
    "Cancel": "Abbrechen",
    "Continue": "Fortsetzen",
    "Coupon: ": "Gutschein:",
    "Creation Time": "Erstellungszeit",
    "Customer": "Kunde",
    "Decrease": "Verringern",
    "Delivery Time": "Lieferzeit",
    "Device": "Gerät",
    "Dinego, your sales partner": "Dinego, Ihr Vertriebspartner",
    "Choose user": "Benutzer auswählen",
    "Select user to unlock Dinego POS, and start your shift": "Benutzer auswählen, um Dinego POS zu entsperren und Ihre Schicht zu beginnen",
    "Due": "Fällig",
    "Email": "E-Mail",
    "Password": "Passwort",
    "Enter your email to reset your password": "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
    "Forgot Password?": "Passwort vergessen?",
    "Email is required": "E-Mail ist erforderlich",
    "Email is invalid": "E-Mail ist ungültig",
    "Email should have at least 3 symbols": "E-Mail sollte mindestens 3 Zeichen enthalten",
    "Email should have maximum 360 symbols": "E-Mail sollte höchstens 360 Zeichen enthalten",
    "Password is required": "Passwort ist erforderlich",
    "Password is invalid": "Passwort ist ungültig",
    "Password should have at least 3 symbols": "Passwort sollte mindestens 3 Zeichen enthalten",
    "Password should have maximum 100 symbols": "Passwort sollte höchstens 100 Zeichen enthalten",
    "Full Name": "Vollständiger Name",
    "Full Name is required": "Der vollständige Name ist erforderlich",
    "Full Name should have at least 3 symbols": "Der vollständige Name muss mindestens 3 Zeichen enthalten",
    "Full Name should have maximum 100 symbols": "Der vollständige Name darf maximal 100 Zeichen enthalten",
    "Please enter both name and surname": "Bitte geben Sie sowohl Vor- als auch Nachname ein",
    "Phone": "Telefon",
    "Phone number is required": "Die Telefonnummer ist erforderlich",
    "Phone number should have at least 3 symbols": "Die Telefonnummer muss mindestens 3 Zeichen enthalten",
    "Phone number should have maximum 100 symbols": "Die Telefonnummer darf maximal 100 Zeichen enthalten",
    "Please enter customer information": "Bitte geben Sie Kundeninformationen ein",
    "Please enter delivery information": "Bitte geben Sie die Lieferinformationen ein",
    "Delivery address": "Lieferadresse",
    "Street address is required": "Straßenadresse ist erforderlich",
    "Street address must be at least 3 characters": "Die Straßenadresse muss mindestens 3 Zeichen lang sein",
    "Street address must be at most 255 characters": "Die Straßenadresse darf höchstens 255 Zeichen lang sein",
    "Type in your address, or position pin on a map for improved accuracy.": "Geben Sie Ihre Adresse ein oder platzieren Sie den Pin auf einer Karte für verbesserte Genauigkeit.",
    "General": "Allgemein",
    "IP Address": "IP-Adresse",
    "IP Address is required": "IP-Adresse ist erforderlich",
    "Including Tip": "Inklusive Trinkgeld",
    "Increase": "Erhöhen",
    "Language": "Sprache",
    "Add a printer using one of above methods": "Drucker mit einer der oben genannten Methoden hinzufügen",
    "List of saved printers": "Liste der gespeicherten Drucker",
    "Load to cart": "In den Warenkorb legen",
    "Location": "Standort",
    "Lock": "Sperren",
    "Log Out": "Abmelden",
    "Logical name": "Logischer Name",
    "Logical name is required": "Logischer Name ist erforderlich",
    "Logout": "Abmelden",
    "Mine only": "Nur meine",
    "Miscellaneous": "Verschiedenes",
    "N/A": "Nicht verfügbar",
    "No connected printers": "Keine verbundenen Drucker",
    "OK": "OK",
    "Order #": "Bestell-Nr.",
    "Order Status": "Bestellstatus",
    "Order Type": "Bestelltyp",
    "Orders": "Bestellungen",
    "Outstanding": "Ausstehend",
    "POS Language": "POS-Sprache",
    "POS Theme": "POS-Thema",
    "Payment #": "Zahlung-Nr.",
    "Payment Method": "Zahlungsmethode",
    "Payment Status": "Zahlungsstatus",
    "Payment Time": "Zahlungszeit",
    "Payment adjustment": "Zahlungskorrektur",
    "Payment amount": "Zahlungsbetrag",
    "Payments": "Zahlungen",
    "Pickup Time": "Abholzeit",
    "Please enter a valid number": "Bitte geben Sie eine gültige Zahl ein",
    "Please wait": "Bitte warten",
    "Port number": "Portnummer",
    "Port number is required": "Portnummer ist erforderlich",
    "Preview order": "Bestellungsvorschau",
    "Print order": "Bestellung drucken",
    "Print receipt": "Beleg drucken",
    "Printer configuration": "Druckerkonfiguration",
    "Receipts": "Belege",
    "Reference #:": "Referenz-Nr.:",
    "Results per page": "Ergebnisse pro Seite",
    "Save": "Speichern",
    "Search coupons": "Gutscheine suchen",
    "Coupon": "Gutschein",
    "Search tables": "Tische suchen",
    "Select coupon to apply": "Gutschein auswählen",
    "Select table": "Tisch auswählen",
    "Sent password reset, please check your email": "Passwortzurücksetzung gesendet, bitte überprüfen Sie Ihre E-Mail",
    "Settings": "Einstellungen",
    "Data synchronization": "Daten Synchronisierung",
    "Update menu data": "Menüdaten aktualisieren",
    "Synchronise local data": "Lokale Daten synchronisieren",
    "Sign in": "Anmelden",
    "Sorry, looks like there are some errors detected, please try again": "Entschuldigung, es wurden einige Fehler festgestellt, bitte versuchen Sie es erneut",
    "Split payment equally": "Zahlung gleichmäßig aufteilen",
    "Submit": "Absenden",
    "Sync now": "Jetzt synchronisieren",
    "Syncing": "Synchronisieren",
    "Table": "Tisch",
    "Tables": "Tische",
    "The login details are incorrect": "Die Anmeldedaten sind falsch",
    "Theme": "Thema",
    "There are no active coupons for your restaurant": "Es gibt keine aktiven Gutscheine für Ihr Restaurant",
    "There are no active tables for your restaurant": "Es gibt keine aktiven Tische für Ihr Restaurant",
    "There are no coupons matching your search": "Es gibt keine Gutscheine, die Ihrer Suche entsprechen",
    "There are no orders matching your search": "Es gibt keine Bestellungen, die Ihrer Suche entsprechen",
    "There are no receipts matching your search": "Es gibt keine Belege, die Ihrer Suche entsprechen",
    "There are no tables matching your search": "Es gibt keine Tische, die Ihrer Suche entsprechen",
    "Tip: ": "Tipp: ",
    "Today": "Heute",
    "Update application language by choosing your preferred language": "Aktualisieren Sie die Anwendungsprache, indem Sie Ihre bevorzugte Sprache auswählen",
    "Update application skin color to dark or light, or use system preferred": "Aktualisieren Sie die Hautfarbe der Anwendung auf dunkel oder hell oder verwenden Sie die bevorzugte Systemfarbe",
    "Update now": "Jetzt aktualisieren",
    "You don't have any floorplans created yet": {
      " Please go to your admin panel and create some": {
        "": "Sie haben noch keine Grundrisse erstellt. Bitte gehen Sie zu Ihrem Administrationsbereich und erstellen Sie einige"
      }
    },
    "discount": "Rabatt",
    "discount on entire order": "Rabatt auf die gesamte Bestellung",
    "out of": "von",
    "Direct sale": "Direktverkauf",
    "No table": "Kein Tisch",
    "Search for products or categories": "Produkte oder Kategorien suchen",
    "There are no categories or products matching your search": "Es gibt keine Kategorien oder Produkte, die Ihrer Suche entsprechen",
    "Product addons": "Produktzusätze",
    "Add course": "Gericht hinzufügen",
    "Total": "Gesamt",
    "Clear order": "Bestellung löschen",
    "Item amount": "Artikelbetrag",
    "Add Adjustment": "Anpassung hinzufügen",
    "Update Adjustment": "Anpassung aktualisieren",
    "Bill adjustment": "Rechnungsanpassung",
    "Delivery cost": "Lieferkosten",
    "Service fee": "Servicegebühr",
    "Tip amount": "Trinkgeldbetrag",
    "Paid amount": "Bezahlt",
    "Total Due": "Gesamtbetrag fällig",
    "Total paid": "Gesamt bezahlt",
    "Remove Course": "Gericht entfernen",
    "Apply Coupon": "Gutschein anwenden",
    "Remove Coupon": "Gutschein entfernen",
    "Fire Order": "Bestellung abschicken",
    "Fire Course": "Gericht abschicken",
    "Remove Item": "Artikel entfernen",
    "Order Amount": "Bestellbetrag",
    "Coupon Amount": "Gutscheinbetrag",
    "Adjustment Amount": "Anpassungsbetrag",
    "Tip Amount": "Trinkgeldbetrag",
    "VAT Amount": "MwSt.-Betrag",
    "VAT Number": "USt-IdNr.",
    "Payment Receipt": "Zahlungsbeleg",
    "Total Paid": "Gesamt bezahlt",
    "Thank you": "Danke",
    "Not paid": "Nicht bezahlt",
    "Current orders": "Aktuelle Bestellungen",
    "Order archive": "Bestellarchiv",
    "Order": "Bestellung",
    "Update Order": "Bestellung aktualisieren",
    "Print Receipt": "Beleg drucken",
    "Transfer order": "Bestellung übertragen",
    "Clear table": "Tisch leeren",
    "Print receipts": "Belege drucken",
    "Transfer orders": "Bestellungen übertragen",
    "New order": "Neue Bestellung",
    "There are no archive orders for this table": "Es gibt keine archivierten Bestellungen für diesen Tisch",
    "There are no active orders for this table": "Es gibt keine aktiven Bestellungen für diesen Tisch",
    "Pay": "Bezahlen",
    "Tip": "Trinkgeld",
    "Payment method": "Zahlungsmethode",
    "Simply click on the button displaying the current value to set a custom amount": "Klicken Sie einfach auf die Schaltfläche, die den aktuellen Wert anzeigt, um einen benutzerdefinierten Betrag festzulegen",
    "Customize the tip, or set it manually to your preference": "Passen Sie das Trinkgeld an oder legen Sie es manuell nach Ihren Wünschen fest",
    "Adjust received amount by setting a custom value with a simple click": "Passen Sie den erhaltenen Betrag an, indem Sie mit einem einfachen Klick einen benutzerdefinierten Wert festlegen",
    "This is change that need to be returned to a customer, based on how much he is paying and how.": "Dies ist das Wechselgeld, das einem Kunden zurückgegeben werden muss, basierend darauf, wie viel er bezahlt und wie.",
    "Keep change as tip": "Wechselgeld als Trinkgeld behalten",
    "Received amount": "Erhaltener Betrag",
    "Change": "Wechselgeld",
    "Split": "Teilen",
    "Bill": "Rechnung",
    "Checkout": "Bezahlen",
    "Paid At": "Bezahlt am",
    "Paid With": "Bezahlt mit",
    "Ordered At": "Bestellt am",
    "Dine-In": "Im Restaurant essen",
    "Takeaway": "Zum Mitnehmen",
    "Delivery": "Lieferung",
    "This week": "Diese Woche",
    "Last week": "Letzte Woche",
    "This month": "Diesen Monat",
    "Last month": "Letzten Monat",
    "Custom range": "Benutzerdefinierter Bereich",
    "Administrator": "Administrator",
    "Restaurant manager": "Restaurantmanager",
    "Employee": "Mitarbeiter",
    "Unknown": "Unbekannt",
    "Created": "Erstellt",
    "Pending": "Ausstehend",
    "Accepted": "Akzeptiert",
    "Ready": "Bereit",
    "Finished": "Abgeschlossen",
    "Authorised": "Genehmigt",
    "Captured": "Erfasst",
    "Settled": "Abgeschlossen",
    "Canceled": "Storniert",
    "Refunded": "Erstattet",
    "Failed": "Fehlgeschlagen",
    "Cash": "Bargeld",
    "Credit Card": "Kreditkarte",
    "Apple pay": "Apple Pay",
    "Google pay": "Google Pay",
    "Twint": "Twint",
    "Clear": "Löschen",
    "Apply": "Anwenden",
    "Course": "Gericht"
  }
};
