// France
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'Choisissez votre langue',
    },
    MENU: {
      REGISTER: 'S\'inscrire',
      NEW: 'Nouveau',
      ACTIONS: 'Actions',
      CREATE_POST: 'Créer un nouveau post',
      PAGES: 'Pages',
      FEATURES: 'Fonctionnalités',
      APPS: 'Applications',
      DASHBOARD: 'Tableau de bord',
    },
    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Soumettre',
        NO_ACCOUNT: 'Pas de compte?',
        SIGNUP_BUTTON: 'S\'inscrire',
        FORGOT_BUTTON: 'Mot de passe oublié',
        BACK_BUTTON: 'Retour',
        PRIVACY: 'Confidentialité',
        LEGAL: 'Mentions légales',
        CONTACT: 'Contact',
        TITLE: 'Rapide, efficace et productif',
        DESCRIPTION: 'Rationalisez les opérations de restauration sur place, à emporter et de livraison en toute simplicité. De la création des commandes à la génération des factures, facilitez le travail de votre équipe et améliorez la satisfaction des clients grâce à notre solution complète.'
      },
      LOGIN: {
        TITLE: 'Se connecter',
        BUTTON: 'Se connecter',
      },
      FORGOT: {
        TITLE: 'Mot de passe oublié?',
        DESC: 'Entrez votre email pour réinitialiser votre mot de passe',
        SUCCESS: 'Votre compte a été réinitialisé avec succès.'
      },
      REGISTER: {
        MAX_NUM_ADDONS: 'Vous pouvez choisir un maximum de {{maxAddons}} suppléments',
        ANY_NUM_ADDONS: 'Vous pouvez choisir un nombre illimité de suppléments'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Nom complet',
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirmer le mot de passe',
        USERNAME: 'Nom d\'utilisateur'
      },
      CART: {
        VAT_PERCENT_LABEL: 'TVA incluse ({{vatPercent}}%)'
      },
      VALIDATION: {
        INVALID: '{{name}} n\'est pas valide',
        REQUIRED: '{{name}} est requis',
        MIN_LENGTH: 'La longueur minimale de {{name}} est de {{min}} caractères',
        AGREEMENT_REQUIRED: 'L\'acceptation des termes et conditions est requise',
        NOT_FOUND: '{{name}} demandé non trouvé',
        INVALID_LOGIN: 'Les informations de connexion sont incorrectes',
        REQUIRED_FIELD: 'Champ requis',
        MIN_LENGTH_FIELD: 'Longueur minimale du champ :',
        MAX_LENGTH_FIELD: 'Longueur maximale du champ :',
        INVALID_FIELD: 'Champ invalide',
      }
    },
    CHECKOUT: {
      SPLIT_PAYMENT: {
        DUE_LABEL: "Le montant dû pour le paiement partagé est de <strong>{{dueAmount}}</strong>.",
        AMOUNT_LABEL: "Chaque personne paiera un montant égal de <strong>{{amount}}</strong>.",
        MESSAGE: "Montant dû pour le paiement partagé de <strong>{{dueAmount}}</strong> entre <strong>{{num}}</strong> personnes. Chaque personne paiera un montant égal de <strong>{{amount}}</strong>."
      }
    },
    SETTINGS: {
      MENU_DATA: {
        TITLE: "Mettre à jour les données du menu",
        DESCRIPTION: "Nous mettons à jour les articles du restaurant périodiquement, pour que le système POS soit synchronisé avec votre menu et fonctionne de manière autonome. La dernière mise à jour a eu lieu",
      },
      SYNCHRONIZE: {
        TITLE: "Synchroniser les données locales",
        DESCRIPTION: "Nous synchronisons les données locales avec le serveur toutes les quelques minutes. Vous pouvez lancer la synchronisation manuellement en cliquant sur ce bouton. La dernière synchronisation a eu lieu",
      }
    },
    "Unlock POS": "Déverrouiller POS",
    "Terms": "Conditions",
    "Plans": "Plans",
    "Contact Us": "Nous contacter",
    "1/2 of bill": "1/2 de la facture",
    "1/3 of bill": "1/3 de la facture",
    "1/4 of bill": "1/4 de la facture",
    "ASAP": "Dès que possible",
    "Action": "Action",
    "Add a Bixolon Web printer": "Ajouter une imprimante Bixolon Web",
    "Add a printer:": "Ajouter une imprimante:",
    "Test print": "Impression de test",
    "Printer": "Imprimante",
    "Line Width": "Largeur de ligne",
    "Selected printer already saved": "Imprimante déjà enregistrée",
    "Printer is not connected": "L'imprimante n'est pas connectée",
    "Adjustment: ": "Ajustement :",
    "Any": "N'importe lequel",
    "Bixolon Web Print": "Impression Web Bixolon",
    "CART": {
      "VAT_PERCENT_LABEL": "TVA incluse ({{vatPercent}}%)"
    },
    "Cancel": "Annuler",
    "Continue": "Continuer",
    "Coupon: ": "Coupon :",
    "Creation Time": "Heure de création",
    "Customer": "Client",
    "Decrease": "Diminuer",
    "Delivery Time": "Heure de livraison",
    "Device": "Appareil",
    SALES_PARTNER: "{{appName}}, votre partenaire commercial",
    "Choose user": "Choisir un utilisateur",
    SELECT_USER_TO_UNLOCK: "Sélectionnez un utilisateur pour déverrouiller {{appName}} POS et commencer votre service",
    "Due": "Dû",
    "Email": "Email",
    "Password": "Mot de passe",
    "Enter your email to reset your password": "Entrez votre email pour réinitialiser votre mot de passe",
    "Forgot Password?": "Mot de passe oublié?",
    "Email is required": "L'email est requis",
    "Email is invalid": "L'email n'est pas valide",
    "Email should have at least 3 symbols": "L'email doit comporter au moins 3 caractères",
    "Email should have maximum 360 symbols": "L'email doit comporter un maximum de 360 caractères",
    "Password is required": "Le mot de passe est requis",
    "Password is invalid": "Le mot de passe n'est pas valide",
    "Password should have at least 3 symbols": "Le mot de passe doit comporter au moins 3 caractères",
    "Password should have maximum 100 symbols": "Le mot de passe doit comporter un maximum de 100 caractères",
    "Full Name": "Nom complet",
    "Full Name is required": "Le nom complet est requis",
    "Full Name should have at least 3 symbols": "Le nom complet doit contenir au moins 3 caractères",
    "Full Name should have maximum 100 symbols": "Le nom complet doit contenir au maximum 100 caractères",
    "Please enter both name and surname": "Veuillez entrer à la fois le nom et le prénom",
    "Please enter customer information": "Veuillez entrer les informations du client",
    "Phone": "Téléphone",
    "Phone number is required": "Le numéro de téléphone est requis",
    "Phone number should have at least 3 symbols": "Le numéro de téléphone doit contenir au moins 3 caractères",
    "Phone number should have maximum 100 symbols": "Le numéro de téléphone doit contenir au maximum 100 caractères",
    "Please enter delivery information": "Veuillez saisir les informations de livraison",
    "Delivery address": "Adresse de livraison",
    "Street address is required": "L'adresse de la rue est requise",
    "Street address must be at least 3 characters": "L'adresse de la rue doit comporter au moins 3 caractères",
    "Street address must be at most 255 characters": "L'adresse de la rue doit comporter au maximum 255 caractères",
    "Type in your address, or position pin on a map for improved accuracy.": "Tapez votre adresse ou positionnez le pin sur une carte pour une meilleure précision.",
    "General": "Général",
    "IP Address": "Adresse IP",
    "IP Address is required": "L'adresse IP est requise",
    "Including Tip": "Pourboire inclus",
    "Increase": "Augmenter",
    "Language": "Langue",
    "Add a printer using one of above methods": "Ajouter une imprimante en utilisant l'une des méthodes ci-dessus",
    "List of saved printers": "Liste des imprimantes enregistrées",
    "Load to cart": "Charger dans le panier",
    "Location": "Emplacement",
    "Lock": "Verrouiller",
    "Log Out": "Déconnexion",
    "Logical name": "Nom logique",
    "Logical name is required": "Le nom logique est requis",
    "Logout": "Se déconnecter",
    "Mine only": "Seulement les miens",
    "Miscellaneous": "Divers",
    "N/A": "Non applicable",
    "No connected printers": "Aucune imprimante connectée",
    "OK": "OK",
    "Order #": "Commande n°",
    "Order Status": "Statut de la commande",
    "Order Type": "Type de commande",
    "Orders": "Commandes",
    "Outstanding": "Exceptionnel",
    "POS Language": "Langue POS",
    "POS Theme": "Thème POS",
    "Payment #": "Paiement n°",
    "Payment Method": "Méthode de paiement",
    "Payment Status": "Statut du paiement",
    "Payment Time": "Heure du paiement",
    "Payment adjustment": "Ajustement de paiement",
    "Payment amount": "Montant du paiement",
    "Payments": "Paiements",
    "Pickup Time": "Heure de collecte",
    "Please enter a valid number": "Veuillez entrer un numéro valide",
    "Please wait": "Veuillez patienter",
    "Port number": "Numéro de port",
    "Port number is required": "Le numéro de port est requis",
    "Preview order": "Aperçu de la commande",
    "Print order": "Imprimer la commande",
    "Print receipt": "Imprimer le reçu",
    "Printer configuration": "Configuration de l'imprimante",
    "Receipts": "Reçus",
    "Reference #:": "Référence n° :",
    "Results per page": "Résultats par page",
    "Save": "Enregistrer",
    "Search coupons": "Rechercher des coupons",
    "Coupon": "Coupon",
    "Search tables": "Rechercher des tables",
    "Select coupon to apply": "Sélectionner un coupon à appliquer",
    "Select table": "Sélectionner une table",
    "Sent password reset, please check your email": "Réinitialisation du mot de passe envoyée, veuillez vérifier votre e-mail",
    "Settings": "Paramètres",
    "Data synchronization": "Synchronisation des données",
    "Update menu data": "Mettre à jour les données du menu",
    "Synchronise local data": "Synchroniser les données locales",
    "Sign in": "Se connecter",
    "Sorry, looks like there are some errors detected, please try again": "Désolé, il semble qu'il y ait des erreurs détectées, veuillez réessayer",
    "Split payment equally": "Partager le paiement équitablement",
    "Submit": "Soumettre",
    "Sync now": "Synchroniser maintenant",
    "Syncing": "Synchronisation",
    "Table": "Table",
    "Tables": "Tables",
    "The login details are incorrect": "Les informations de connexion sont incorrectes",
    "Theme": "Thème",
    "There are no active coupons for your restaurant": "Il n'y a pas de coupons actifs pour votre restaurant",
    "There are no active tables for your restaurant": "Il n'y a pas de tables actives pour votre restaurant",
    "There are no coupons matching your search": "Aucun coupon ne correspond à votre recherche",
    "There are no orders matching your search": "Aucune commande ne correspond à votre recherche",
    "There are no receipts matching your search": "Aucun reçu ne correspond à votre recherche",
    "There are no tables matching your search": "Aucune table ne correspond à votre recherche",
    "Tip: ": "Astuce : ",
    "Today": "Aujourd'hui",
    "Update application language by choosing your preferred language": "Mettez à jour la langue de l'application en choisissant votre langue préférée",
    "Update application skin color to dark or light, or use system preferred": "Mettez à jour la couleur de l'interface en mode clair ou sombre, ou utilisez celle du système",
    "Update now": "Mettre à jour maintenant",
    "You don't have any floorplans created yet": {
      " Please go to your admin panel and create some": {
        "": "Vous n'avez encore créé aucun plan de salle. Veuillez aller dans votre panneau d'administration et en créer"
      }
    },
    "discount": "remise",
    "discount on entire order": "remise sur la commande entière",
    "out of": "sur",
    "Direct sale": "Vente directe",
    "No table": "Pas de table",
    "Search for products or categories": "Rechercher des produits ou des catégories",
    "There are no categories or products matching your search": "Aucune catégorie ou produit ne correspond à votre recherche",
    "Product addons": "Ajouts de produits",
    "Add course": "Ajouter un plat",
    "Total": "Total",
    "Clear order": "Effacer la commande",
    "Item amount": "Montant de l'article",
    "Add Adjustment": "Ajouter un ajustement",
    "Update Adjustment": "Mettre à jour l'ajustement",
    "Bill adjustment": "Ajustement de facture",
    "Delivery cost": "Coût de livraison",
    "Service fee": "Frais de service",
    "Tip amount": "Montant du pourboire",
    "Paid amount": "Montant payé",
    "Total Due": "Total dû",
    "Total paid": "Total payé",
    "Remove Course": "Retirer le plat",
    "Apply Coupon": "Appliquer le coupon",
    "Remove Coupon": "Retirer le coupon",
    "Fire Order": "Envoyer la commande",
    "Fire Course": "Envoyer le plat",
    "Remove Item": "Retirer l'article",
    "Order Amount": "Montant de la commande",
    "Coupon Amount": "Montant du coupon",
    "Adjustment Amount": "Montant de l'ajustement",
    "Tip Amount": "Montant du pourboire",
    "VAT Amount": "Montant de la TVA",
    "VAT Number": "Numéro de TVA",
    "Payment Receipt": "Reçu de paiement",
    "Total Paid": "Total payé",
    "Thank you": "Merci",
    "Not paid": "Non payé",
    "Current orders": "Commandes en cours",
    "Order archive": "Archives des commandes",
    "Order": "Commande",
    "Update Order": "Mettre à jour la commande",
    "Print Receipt": "Imprimer le reçu",
    "Transfer order": "Transférer la commande",
    "Clear table": "Nettoyer la table",
    "Print receipts": "Imprimer les reçus",
    "Transfer orders": "Transférer les commandes",
    "New order": "Nouvelle commande",
    "There are no archive orders for this table": "Il n'y a pas de commandes archivées pour cette table",
    "There are no active orders for this table": "Il n'y a pas de commandes actives pour cette table",
    "Pay": "Payer",
    "Tip": "Pourboire",
    "Payment method": "Méthode de paiement",
    "Simply click on the button displaying the current value to set a custom amount": "Cliquez simplement sur le bouton affichant la valeur actuelle pour définir un montant personnalisé",
    "Customize the tip, or set it manually to your preference": "Personnalisez le pourboire ou définissez-le manuellement selon votre préférence",
    "Adjust received amount by setting a custom value with a simple click": "Ajustez le montant reçu en définissant une valeur personnalisée d'un simple clic",
    "This is change that need to be returned to a customer, based on how much he is paying and how.": "Ceci est la monnaie qui doit être rendue à un client, en fonction de combien il paie et comment.",
    "Keep change as tip": "Garder la monnaie comme pourboire",
    "Received amount": "Montant reçu",
    "Change": "Monnaie",
    "Split": "Partager",
    "Bill": "Facture",
    "Checkout": "Paiement",
    "Paid At": "Payé à",
    "Paid With": "Payé avec",
    "Ordered At": "Commandé à",
    "Dine-In": "Sur place",
    "Takeaway": "À emporter",
    "Delivery": "Livraison",
    "This week": "Cette semaine",
    "Last week": "La semaine dernière",
    "This month": "Ce mois-ci",
    "Last month": "Le mois dernier",
    "Custom range": "Plage personnalisée",
    "Administrator": "Administrateur",
    "Restaurant manager": "Gérant de restaurant",
    "Employee": "Employé",
    "Unknown": "Inconnu",
    "Created": "Créé",
    "Pending": "En attente",
    "Accepted": "Accepté",
    "Ready": "Prêt",
    "Finished": "Terminé",
    "Authorised": "Autorisé",
    "Captured": "Capturé",
    "Settled": "Réglé",
    "Canceled": "Annulé",
    "Refunded": "Remboursé",
    "Failed": "Échoué",
    "Cash": "Espèces",
    "Credit Card": "Carte de crédit",
    "Apple pay": "Apple Pay",
    "Google pay": "Google Pay",
    "Twint": "Twint",
    "Clear": "Effacer",
    "Apply": "Appliquer",
    "Course": "Plat",
    "Server Print": "Impression du serveur",
    "Serial number": "Numéro de série",
    "Add a Server printer": "Ajouter une imprimante serveur",
    "Edit a Server printer": "Modifier une imprimante serveur",
    "Printer name": "Nom de l'imprimante",
    "Printer name is required.": "Le nom de l'imprimante est requis.",
    "Serial number is required.": "Le numéro de série est requis.",
    "Select language": "Choisir la langue",
    "Auto print": "Impression automatique",
    "Description": "Description",
    "Print receipt with": "Imprimer le reçu avec",
  }
};
