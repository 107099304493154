import Dexie, { Table } from 'dexie';
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { InstallmentModel, OrderItem, OrderModel, PaymentModel, SelectedItemType } from "../models/order.model";
import { AuthService } from '../../modules/auth/services/auth.service';
import { OrderFilterInterface, PaymentFilterInterface } from '../interfaces/query-params.interface';
import { ORDER_STATUS, PAYMENT_STATUS } from '../models/globals';
import { PosService } from './pos.service';
import moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class OfflineStorageService extends Dexie {

    private static DEFAULT_PAGE_SIZE = 20;

    orders: Table<OrderModel, string>;
    payments: Table<PaymentModel, string>;

    constructor(
        private auth: AuthService,
        private posService: PosService
    ) {
        super(environment.localDb);
        this.version(1).stores({
            orders: 'uuid, id, createdAt, status',
            payments: 'uuid, orderId, paymentMethod, createdAt, transactionTime, status',
        });

        this.orders = this.table('orders');
        this.payments = this.table('payments');
    }

    async createOrder(order: OrderModel) {

        if (order.id) {
            await this.createOrderAndMasterPayment(order);

            return order;
        }

        order = this.populateServiceAttributes(order) as OrderModel;

        order.calculateTotals();
        order.status = ORDER_STATUS.accepted;
        order.restaurant = this.getRestaurant();
        order.masterPayment = order.masterPayment || this.createMasterPayment(order);

        await this.createOrderAndMasterPayment(order);

        return order;
    }

    private async createOrderAndMasterPayment(order: OrderModel) {

        const masterOrder = new OrderModel({
            ...order,
            restaurant: undefined
        });

        await this.orders.add(masterOrder.asPlainObject());

        const masterPayment = new PaymentModel({
            ...order.masterPayment,
            order: new OrderModel({
                ...order,
                restaurant: undefined,
                masterPayment: undefined,
            }).asPlainObject()
        });

        await this.payments.add(masterPayment.asPlainObject());
    }

    async updateOrder(order: OrderModel) {

        order.calculateTotals();
        this.populateServiceAttributes(order);

        const cleanOrder = new OrderModel({
            ...order.toApi(),
            table: order.table,
            tableCode: order.tableCode,
            tipAmount: order.tipAmount,
            itemAmount: order.itemAmount,
            discountAmount: order.discountAmount,
            deliveryAmount: order.deliveryAmount,
            additionalAmount: order.additionalAmount,
            orderAmount: order.orderAmount,
            totalAmount: order.totalAmount,
            createdAt: undefined,
            createdBy: undefined,
        }).asPlainObject();

        await this.orders.update(order.uuid, cleanOrder);

        let dbOrder = await this.orders.get(order.uuid);

        if (dbOrder?.masterPayment) {
            const paymentData = {
                order: new OrderModel({
                    ...dbOrder,
                    restaurant: undefined,
                    masterPayment: undefined,
                }).asPlainObject(),
                totalAmount: dbOrder.totalAmount,
                tipAmount: dbOrder.tipAmount,
                updatedAt: dbOrder.updatedAt,
                updatedBy: dbOrder.updatedBy,
            };

            await this.payments.update(dbOrder.masterPayment.uuid, paymentData);
        } else {
          await this.createOrderAndMasterPayment(order);
          dbOrder = await this.orders.get(order.uuid);
        }

        return dbOrder;
    }

    async createInstallment(installment: InstallmentModel) {

        const orderData = await this.orders.get(installment.orderUuid!);

        if (!orderData?.masterPayment) {
            throw new Error('Master payment not present in order.');
        }

        const paymentData = await this.payments.get(orderData.masterPayment.uuid);

        if (!paymentData) {
            throw new Error('Master payment is missing in local DB.');
        }

        if (installment.tipAmount) {
            orderData.tipAmount = (orderData.tipAmount || 0) + installment.tipAmount;
            orderData.totalAmount = (orderData.totalAmount || 0) + installment.tipAmount;
        }

        this.populateServiceAttributes(installment);
        const masterPaymentModel = new PaymentModel({
            ...paymentData,
            order: orderData
        });
        masterPaymentModel.addInstallment(installment);
        this.populateServiceAttributes(masterPaymentModel);

        orderData.masterPayment = new PaymentModel({
            ...masterPaymentModel,
            order: undefined
        }).asPlainObject();

        await this.payments.update(masterPaymentModel.uuid, masterPaymentModel.asPlainObject());

        const installmentPayment = masterPaymentModel.installments.pop() || masterPaymentModel;
        installment.payment = new PaymentModel({
            ...installmentPayment,
            ...installment.payment,
            id: installmentPayment.id,
            uuid: installmentPayment.uuid,
        });

        const itemMap = new Map<string, SelectedItemType>();

        installment.selectedItems.forEach(({ courseIndex, itemIndex }) => {
          const key = `${courseIndex}-${itemIndex}`;
          if (itemMap.has(key)) {
            itemMap.get(key)!.quantity! += 1;
          } else {
            itemMap.set(key, { courseIndex, itemIndex, quantity: 1 });
          }
        });

        const transformedSelectedItems =  Array.from(itemMap.values());

        transformedSelectedItems?.forEach((selectedItem: SelectedItemType) => {
            let index = 0;
            for (let i = 0; i < orderData.orderItems.length; i++) {
                if (orderData.orderItems[i].course == selectedItem.courseIndex) {
                    if (index == selectedItem.itemIndex) {
                      if(orderData.orderItems[i].quantity > 1 && orderData.orderItems[i].quantity != selectedItem.quantity) {
                        const newOrderItem = new OrderItem({
                          addonAmount: orderData.orderItems[i].addonAmount,
                          addons: orderData.orderItems[i].addons,
                          course: orderData.orderItems[i].course,
                          name: orderData.orderItems[i].name,
                          note: orderData.orderItems[i].note,
                          productId: orderData.orderItems[i].productId,
                          status: orderData.orderItems[i].status,
                          type: orderData.orderItems[i].type,
                          unitPrice: orderData.orderItems[i].unitPrice,
                          quantity: orderData.orderItems[i]?.quantity - (selectedItem.quantity || 1),
                          totalAmount: (orderData.orderItems[i]?.addonAmount + orderData.orderItems[i]?.unitPrice) * (selectedItem.quantity || 1)
                        })
                        orderData.orderItems.push(newOrderItem)

                        orderData.orderItems[i].quantity = selectedItem.quantity || 1
                      }

                      orderData.orderItems[i].paymentId = installment.payment?.id;
                      orderData.orderItems[i].paymentUuid = installment.payment?.uuid;
                      break;
                    }
                    index++;
                }
            }
        });

        await this.orders.update(orderData.uuid, orderData);

        return installment;
    }

    async updatePayment(payment: PaymentModel) {

        await this.payments.update(payment.uuid, payment);

        return payment;
    }

    async getPaginatedOrders(filter: OrderFilterInterface = {}) {
        const page = filter.page || 1;
        const limit = filter['per-page'] || OfflineStorageService.DEFAULT_PAGE_SIZE;
        const offset = (page - 1) * limit;

        const query = this.getOrdersQuery(filter);

        const count = await query.count();

        const paginatedQuery = query.offset(offset).limit(limit);
        const orders = await paginatedQuery.toArray();

        return {
            page: page,
            'per-page': limit,
            totalRecords: count,
            data: orders.map(data => new OrderModel(data))
        }
    }

    async getPaginatedPayments(filter: OrderFilterInterface = {}) {
        const page = filter.page || 1;
        const limit = filter['per-page'] || OfflineStorageService.DEFAULT_PAGE_SIZE;
        const offset = (page - 1) * limit;

        const query = this.getPaymentsQuery(filter);

        const count = await query.count();

        const paginatedQuery = query.offset(offset).limit(limit);
        const payments = await paginatedQuery.toArray();

        return {
            page: page,
            'per-page': limit,
            totalRecords: count,
            data: payments.map(data => new PaymentModel(data))
        }
    }

    async getOrders(filter: OrderFilterInterface = {}) {
        const page = filter.page || 1;
        const limit = filter['per-page'] || OfflineStorageService.DEFAULT_PAGE_SIZE;
        const offset = (page - 1) * limit;
        const orders = await this.getOrdersQuery(filter).offset(offset).limit(limit).toArray();

        return orders.map(data => new OrderModel(data));
    }

    async bulkUpdateOrders(orders: OrderModel[]) {

        const ordersUpdate = orders.map((order: OrderModel) => { return { key: order.uuid, changes: order } });

        await this.orders.bulkUpdate(ordersUpdate);

        const paymentsUpdate = orders.map((order) => {
            return {
                key: order.masterPayment.uuid,
                changes: {
                    order: new OrderModel({
                        ...order,
                        restaurant: undefined,
                        masterPayment: undefined,
                    }).asPlainObject(),
                    totalAmount: order.totalAmount,
                    tipAmount: order.tipAmount,
                    updatedAt: order.updatedAt,
                    updatedBy: order.updatedBy,
                }
            };
        });

        await this.payments.bulkUpdate(paymentsUpdate);

        return orders;
    }

    async getOrdersChunk(chunkSize: number, offset: number): Promise<any[]> {
        return this.orders.offset(offset).limit(chunkSize).toArray();
    }

    async getOrdersTotalCount(): Promise<number> {
        return this.orders.count();
    }

    private createMasterPayment(order: OrderModel): PaymentModel {
        let masterPayment = new PaymentModel(order.masterPayment);
        masterPayment = this.populateServiceAttributes(masterPayment) as PaymentModel;
        masterPayment.orderId = order.id!;
        masterPayment.orderUuid = order.uuid;
        masterPayment.currency = PosService.getCurrencyConfig().code || environment.appCurrency;
        masterPayment.totalAmount = order.totalAmount;
        masterPayment.status = PAYMENT_STATUS.pending;
        masterPayment.vatPercent = order.getVatPercent();

        return masterPayment;
    }

    private getOrdersQuery(filter: OrderFilterInterface = {}) {
        const orderDateFrom = filter?.order_date_from || '';
        const orderDateTo = filter?.order_date_to || '';
        const orderId = filter?.id;
        const orderStatuses = filter?.statuses && filter.statuses.split(',');
        const orderTypes = filter?.order_types && filter.order_types.split(',');
        const masterPaymentStatuses = filter?.master_payment_statuses && filter.master_payment_statuses.split(',');
        const tableId = filter?.table_id;

        return this.orders
            .orderBy('createdAt')
            .reverse()
            .filter((order) => !orderId || orderId == order.id)
            .filter((order) => !orderStatuses || !order.status || orderStatuses.includes(order.status))
            .filter((order) => !orderTypes || !order.orderType  || orderTypes.includes(order.orderType))
            .filter((order) => !masterPaymentStatuses || !order.masterPayment?.status || masterPaymentStatuses.includes(order.masterPayment.status))
            .filter((order) => {
              if (filter.is_current) {
                return order.status !== ORDER_STATUS.canceled && order.status !== ORDER_STATUS.finished;
              }
              return true;
            })
            .filter((order) => {
              if (filter.is_archive) {
                return order.status === ORDER_STATUS.finished;
              }
              return true;
            })
            .filter((order) => !filter.has_table || !!order.table)
            .filter((order) => !tableId || (!!order.table?.id && order.table.id == tableId))
            .filter((order) => !order.createdAt || !orderDateFrom || !orderDateTo || moment(new Date(order.createdAt * 1000)).isBetween(orderDateFrom, orderDateTo, 'minute', '[]'));
    }

    private getPaymentsQuery(filter: PaymentFilterInterface = {}) {
        const paymentDateFrom = filter?.order_date_from || '';
        const paymentDateTo = filter?.order_date_to || '';
        const paymentId = filter?.id;
        const paymentStatuses = filter?.payment_statuses && filter.payment_statuses.split(',');
        const paymentMethods = filter?.payment_methods && filter.payment_methods.split(',');

        return this.payments
            .orderBy('createdAt')
            .reverse()
            .filter((payment) => !paymentId || paymentId == payment.id)
            .filter((payment) => !paymentStatuses || paymentStatuses.includes(payment.status))
            .filter((payment) => !paymentMethods || paymentMethods.includes(payment.paymentMethod))
            .filter((payment) => (payment.transactionTime && moment(new Date(payment.transactionTime * 1000)).isBetween(paymentDateFrom, paymentDateTo, 'second', '[]')) || (!payment.createdAt || moment(new Date(payment.createdAt * 1000)).isBetween(paymentDateFrom, paymentDateTo, 'second', '[]')));
    }

    private populateServiceAttributes(model: PaymentModel | OrderModel | InstallmentModel) {
        model.uuid = model.uuid || this.getRandomUUID();
        model.createdAt = model.createdAt || this.getCurrentTimestamp();
        model.createdBy = model.createdBy || this.getCurrentUser()?.id;
        model.updatedAt = this.getCurrentTimestamp();
        model.updatedBy = this.getCurrentUser()?.id;

        return model;
    }

    private getRandomUUID() {
        return crypto.randomUUID();
    }

    private getCurrentTimestamp() {
        return Math.floor(Date.now() / 1000);
    }

    private getCurrentUser() {
        return this.auth.currentUserValue;
    }

    private getRestaurant() {
        return this.posService.getRestaurantSnapshot();
    }
}
