import { BaseModel } from './base.model';
import { UserModel } from './user.model';
import { OrderItem, OrderModel } from './order.model';
import moment from 'moment/moment';
import {
  CurrencyType,
  ORDER_ITEM_STATUS,
  ORDER_WAIT_TIME_LIMIT,
  TABLE_ACTIVE_TIME_LIMIT,
  TABLE_STATUS_COLORS
} from './globals';
import { PosService } from '../services/pos.service';

function _(t: string) {
  return t;
} //placeholed until '@biesbjerg/ngx-translate-extract-marker'; is added

export class TableModel extends BaseModel {
  id: number;
  name: string;
  code: string;
  seatingCount: number;
  waiter: UserModel;
  tableUrl: string;
  qrCodeUrl: string;
  isActive: number;
  activeOrders?: OrderModel[];

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get childModels(): {} {
    return {
      waiter: UserModel,
      activeOrders: OrderModel
    };
  }

  get initialValues() {
    return {
      activeOrders: [],
    };
  }

  getStatusColor() {
    if (!this.hasActiveOrders()) {
      return TABLE_STATUS_COLORS.free;
    }

    if (this.isTableActiveLimitExceeded()) {
      return TABLE_STATUS_COLORS.overdue;
    }

    if (this.hasReadyOrderItems()) {
      return TABLE_STATUS_COLORS.active;
    }

    if (this.isWaitTimeLimitExceeded()) {
      return TABLE_STATUS_COLORS.overdue;
    }

    return TABLE_STATUS_COLORS.pending;
  }

  getOrderStartedTime() {
    if (!this.activeOrders?.length) {
      return;
    }

    return this.activeOrders[this.activeOrders.length - 1]?.createdAtDateObject;
  }

  getTotalDueAmount() {
    if (!this.activeOrders?.length) {
      return 0;
    }

    let totalDueAmount = 0;
    for (const order of this.activeOrders) {
      totalDueAmount += order.masterPayment?.getDueAmount() || 0;
    }

    return totalDueAmount;
  }

  hasActiveOrders(): boolean {
    return !!this.activeOrders?.length;
  }

  hasReadyOrderItems(): boolean {
    const orderItems = this.activeOrders?.map((order: OrderModel) => order.orderItems).reduce((acc: OrderItem[], orderItems: OrderItem[]) => {
      return acc.concat(orderItems);
    }, []) || [];

    return orderItems.some((orderItem: OrderItem) => orderItem.status === ORDER_ITEM_STATUS.ready || orderItem.status === ORDER_ITEM_STATUS.finished);
  }

  isWaitTimeLimitExceeded(): boolean {
    return this.getWaitTime() > ORDER_WAIT_TIME_LIMIT
  }

  isTableActiveLimitExceeded(): boolean {
    return this.getWaitTime() > TABLE_ACTIVE_TIME_LIMIT
  }

  getWaitTime() {
    return moment().diff(moment(this.getOrderStartedTime()), 'minutes');
  }

  getFloorplanTableTexts() {
    const waitTime = this.getWaitTime();
    const hours = Math.floor(waitTime / 60);
    const minutes = waitTime % 60;
    const timeText = this.hasActiveOrders() ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}` : '';

    let totalAmountText = !this.hasActiveOrders() ? '' : _('Settled');

    if (!!this.getTotalDueAmount()) {
      const currency: CurrencyType = PosService.getCurrencyConfig();
      totalAmountText = currency.isPrefix ? `${currency.value}${this.getTotalDueAmount().toFixed(2)}` : `${this.getTotalDueAmount().toFixed(2)}${currency.value}`;
    }

    return [
      timeText,
      this.name || '',
      totalAmountText
    ];
  }
}
