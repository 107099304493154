// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      REGISTER: 'Register',
    },
    REGISTER: {
      MAX_NUM_ADDONS: 'You may choose maximum of {{maxAddons}} addons',
      ANY_NUM_ADDONS: 'You can choose any number of addons'
    },
    CART: {
      VAT_PERCENT_LABEL: 'Including VAT ({{vatPercent}}%)'
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        TITLE: 'Fast, efficient & productive',
        DESCRIPTION: 'Streamline dine-in, takeaway, and delivery operations with ease. From creating orders to generating bills, empower your staff and enhance customer satisfaction with our comprehensive solution.'
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    CHECKOUT: {
      SPLIT_PAYMENT: {
        DUE_LABEL: "Split payment due amount of <strong>{{dueAmount}}</strong> equally.",
        AMOUNT_LABEL: "Each person will pay equal amount of <strong>{{amount}}</strong>.",
        MESSAGE: "Split payment due of <strong>{{dueAmount}}</strong> between <strong>{{num}}</strong> persons. Each person will pay eaqul amount of <strong>{{amount}}</strong>."
      }
    },
    SETTINGS: {
      MENU_DATA: {
        TITLE: "Update menu data",
        DESCRIPTION: "We update restaurant items every once in a while, for POS system to be both synchronised with your menu, and standalone. Last update was",
      },
      SYNCHRONIZE: {
        TITLE: "Synchronise local data",
        DESCRIPTION: "We synchronise local data with server every few minutes. You can start it manually clicking on this button. Last sync was",
      }
    },
    SALES_PARTNER: "{{appName}}, your sales partner",
    SELECT_USER_TO_UNLOCK: "Select user to unlock {{appName}} POS, and start your shift",
    ERROR_MESSAGES: {
      CREATE_INSTALLMENT: "Unable to create installment",
    },
    CERTIFICATE_WARNING: {
      SECURITY_WARNING: 'Security Warning',
      WRONG_PARAMS: 'Wrong printer parameters.',
      NOT_USING_CERTIFICATE: "Check printer's Device ID and IP address. If they are correct than Your printer is not using a security certificate. This may prevent secure communication and could cause connection issues when trying to print over a secure network.",
      HOW_TO_ENABLE_CERTIFICATE: "How to enable a certificate for secure printing:",
      OPEN_PRINTERS_IP_ADDRESS: "Open the printer’s HTTPS page (https://{printer_ip_address})",
      GO_TO_ADVANCED_SETTINGS: "Go to Advanced Settings",
      GO_TO_SSL_CERTIFICATES: "Navigate to Network Security > SSL/TLS > Certificate",
      CHOOSE_DOWNLOAD: "Under Self-signed, choose Download",
      DOWNLOAD_NOT_AVAILABLE: "If the Download button is not available, follow these steps:",
      ENTER_COMMON_NAME: "Click Create and enter printers IP address (or name of your certificate if it is different than printer's IP address) under Common Name",
      CLICK_NOT_SECURE: "Click the lock icon or Not Secure in the browser’s address bar",
      SELECT_DETAILS: "Select View Certificate or Details (option varies by browser)",
      EXPORT: "Click Export / Download the certificate / Copy to file (usually a .crt file)",
      INSTALL_CERTIFICATE: "Install the certificate on your operating system:",
      INSTALL_ON_WINDOWS: "Windows: Open certmgr.msc, go to Trusted Root Certification Authorities and import the certificate",
      INSTALL_ON_MAC: "Mac: Open Keychain Access, add the certificate, and set it as Always Trust",
      RESTART_BROWSER: "Restart your browser and try connecting again.",
      CONNTACT: "If you are unsure how to proceed, please contact your system administrator or refer to the printer’s documentation."
    }
  }
};
