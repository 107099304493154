// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appVersion: 'v1.0.0',
  localDb: 'dinego',
  STORAGE_KEY: 'E7diiSOYzq',
  apiUrl: 'https://api.dinego-dev.singularity.is/v1',
  adminUrl: 'https://admin.dinego-dev.singularity.is',
  frontendUrl: 'https://dinego-dev.singularity.is',
  clientId: 'dinego-dev',
  clientSecret: 'TbXwsSpr5EkLbddl4TcSCZr3qucQoti4',
  googleApiKey: 'AIzaSyA5Z9XRAAscVgNwMyAU3waS11O4o2y1nhc',
  googleMapDefaultCenter: {lat: 47.38969, lng: 8.538081},
  googleMapDefaultZoomLevel: 16,
  appLanguage: 'en',
  appCurrency: 'CHF',
  connectionCheckIntervalMs: 60000,
  appName: 'Dinego',
  appLogo: 'assets/logo/logo-light.svg'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
