import { Injectable } from '@angular/core';
import { BehaviorSubject, from, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InstallmentModel, PaymentModel } from '../models/order.model';
import { catchError, switchMap } from 'rxjs/operators';
import { ToasterService } from './toaster.service';
import { PaymentFilterInterface } from '../interfaces/query-params.interface';
import { environment } from '../../../environments/environment';
import { PaginatedDataInterface } from '../interfaces/pagination.interface';
import { OnlineService } from './online.service';
import { OfflineStorageService } from './offline-storage.service';
import { ResponseHelper } from '../helpers/response.helper';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _paymentsStorageKey = `payments-${environment.appVersion}-${environment.STORAGE_KEY}`;
  private _paymentsFilterStorageKey = `payments-filter-${environment.appVersion}-${environment.STORAGE_KEY}`;

  private paymentsSubject: BehaviorSubject<PaginatedDataInterface> = new BehaviorSubject<PaginatedDataInterface>({} as PaginatedDataInterface);

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private onlineService: OnlineService,
    private offlineStorage: OfflineStorageService,
    private translateService: TranslateService,
  ) {
  }

  createInstallment(installment: InstallmentModel, queryParams: any = {}) {
    if (!this.onlineService.getIsOnlineValue()) {
      return from(this.offlineStorage.createInstallment(installment)).pipe(
        catchError((e) => this.handleError(e, this.translateService.instant('ERROR_MESSAGES.CREATE_INSTALLMENT')))
      );
    }

    queryParams.expand = queryParams.expand ? queryParams.expand : 'payment.order.order_items.addons';
    return this.http.post<InstallmentModel>('/pos/payments', installment?.toApi(), { params: queryParams }).pipe(
      switchMap((response: InstallmentModel) => {
        return from(this.offlineStorage.createInstallment(new InstallmentModel(response))).pipe(
          catchError((e) => this.handleError(e, this.translateService.instant('ERROR_MESSAGES.CREATE_INSTALLMENT')))
        );
      }),
      catchError((e) => this.handleError(e, this.translateService.instant('ERROR_MESSAGES.CREATE_INSTALLMENT')))
    );
  }

  private getPaymentsWithHeaders(queryParams: any): any {
    queryParams.expand = 'order.order_items.addons,installments.order.order_items.addons,order.coupon';
    return this.http.get<any>('/pos/payments', { params: queryParams, observe: 'response' });
  }

  getPayments(queryParams: any = {}): Observable<PaginatedDataInterface> {

    if (!this.onlineService.getIsOnlineValue()) {
      return from(this.offlineStorage.getPaginatedPayments(queryParams));
    }

    return this.getPaymentsWithHeaders(queryParams).pipe(
      map((response: any) => {
        const pagination = ResponseHelper.extractPagination(response);
        const payments = response.body.map((item: any) => new PaymentModel(item));

        return {
          data: payments,
          page: pagination.page,
          totalRecords: pagination.total,
          'per-page': pagination.pageSize
        }
      })
    );
  }

  getPaymentsFilterFromStorage(): PaymentFilterInterface {
    const storedPaymentsFilter = localStorage.getItem(this._paymentsFilterStorageKey);
    if (!storedPaymentsFilter) {
      return {};
    }

    return JSON.parse(storedPaymentsFilter);
  }

  setPaymentsFilterInStorage(filterParams: PaymentFilterInterface): void {
    localStorage.setItem(this._paymentsFilterStorageKey, JSON.stringify(filterParams));
  }

  private handleError(e: any, message: string) {
    this.toasterService.showDanger(
      e.error?.errors?.length ?
        (typeof e.error.errors[0] === 'string' ?
          this.translateService.instant(e.error.errors[0])
          : e.error.errors.join('<br>'))
        :  e.message, message);
    return of(undefined);
  }

}
